<template>
  <div>
    <home-layout
      :leftBannerList="leftBannerList"
      :rightBannerList="rightBannerList"
      :navLeftList="navLeftList"
      :navRightList="navRightList"
      :hotList="hotList"
      :hotShowList="hotShowList"
      @preview="preview"
    >
      <template #bannerSearch>
        <div class="banner-search">
          <div class="temCenter-search-wrap">
            <div class="search-wrap">
              <div class="search">
                <a class="icon-box" @click="search">
                  <i class="el-icon-search"></i>
                </a>
                <el-autocomplete
                  popper-class="autocomplete"
                  v-model="searchValue"
                  :fetch-suggestions="querySearch"
                  placeholder="请输入内容"
                >
                  <template slot-scope="{ item }">
                    <div class="name">{{ item.value }}</div>
                    <span class="result">{{ item.result }}条结果</span>
                  </template>
                </el-autocomplete>
              </div>
            </div>
          </div>
        </div>
      </template>
    </home-layout>
  </div>
</template>

<script>
import homeLayout from "@/components/home/index.vue";
import { getBannerList } from "../../api/home";
import { getMainList } from "../../api/home";
import { brandsList } from "../../api/home";
export default {
  components: {
    homeLayout
  },
  data() {
    return {
      restaurants: [],
      searchValue: "",
      leftBannerList: [],
      rightBannerList: [
        {
          children: [
            { image: require("@/assets/images/home/banner_blue.png") },
            { image: require("@/assets/images/home/banner_green.png") }
          ]
        },
        {
          children: [
            { image: require("@/assets/images/home/banner_yellow.png") },
            { image: require("@/assets/images/home/banner_red.png") }
          ]
        }
      ],
      navLeftList: [],
      navRightList: [
        { image: require("@/assets/images/home/item1.png"), name: "手机端" },
        { image: require("@/assets/images/home/item2.png"), name: "我的设计" }
      ],
      hotList: [],
      hotShowList: []
    };
  },
  mounted() {
    this.restaurants = this.loadAll();
    this.getHomeList();
    this.getBannerList();
    this.getBrandsList();
  },
  methods: {
    //搜索处理
    querySearch(queryString, cb) {
      var restaurants = this.restaurants;
      var results = queryString
        ? restaurants.filter(this.createFilter(queryString))
        : restaurants;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    createFilter(queryString) {
      return restaurant => {
        return (
          restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0
        );
      };
    },
    //搜索列表
    loadAll() {
      return [
        { value: "Hot honey 首尔炸鸡（仙霞路）", result: 12 },
        { value: "新旺角茶餐厅", result: 32 },
        { value: "首尔炸鸡（仙霞路）", result: 56 }
      ];
    },
    //点击触发搜索
    search() {
      console.log('搜索:' + this.searchValue);
      this.$router.push({
          path:  '/materialSearch'
      })
    },
    preview(index, params) {
      console.log(index, params);
    },
    getHomeList(){
      getMainList({}).then(result => {
        this.navLeftList = result.categoryList;
        this.hotShowList = result.goodsList;
      }).catch(error => {
        console.log(JSON.stringify(error));
      });
    },
    getBannerList(){
      let dataform = {
        position: 1
      };
      getBannerList(dataform).then(result => {
        this.leftBannerList = result;
      }).catch(error => {
        console.log(JSON.stringify(error));
      });
    },
    getBrandsList(){
      brandsList().then(result => {
        console.log(JSON.stringify(result));
        this.hotList = result;
      }).catch(error => {
        console.log(JSON.stringify(error));
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.banner-search {
  height: 240px;
  border-radius: 8px;
  background-size: cover;
  background-image: url(../../assets/images/material/designIndex-content_banner.jpg);
  background-position: 0 0;
  position: relative;
  animation: imgMove 70s linear infinite;
  margin-bottom: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 8px;
  }
  .temCenter-search-wrap {
    width: 100%;
    min-width: 260px;
    flex-shrink: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 2;
  }
  .search {
    position: relative;
    width: 100%;
    height: 100%;
  }
}
@keyframes imgMove {
  0% {
    background-position: 0 0;
  }
  to {
    background-position: 0 100%;
  }
}
</style>